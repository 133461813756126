<template>
  <a-card :bordered='false'>
   <a-row class="query-row" type='flex' :gutter="12">
      <a-col style="width:290px">
           <span>用户union_id：</span>
           <a-input v-model="queryData.unionId" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:240px">
           <span>用户手机号：</span>
           <a-input v-model="queryData.mobile" allowClear placeholder='请输入'></a-input>
        </a-col>
        <!-- <a-col style="width:220px">
           <span>推荐人：</span>
           <a-input v-model="queryData.shareMobile" allowClear placeholder='请输入'></a-input>
        </a-col> -->
        <a-col>
           <a-button @click="() => {this.pageNum = 1;this.loadTableData()}" type='primary'>查询</a-button>
        </a-col>
      </a-row>

      <a-table :columns="columns"  :dataSource="tableData" :pagination="false"
               :loading="loading"
               :locale='locale'
               :scroll="{x: true}"
               size="default">
      </a-table>
  </a-card>
</template>

<script>
import moment from 'moment'
import api from '@/api'
export default {
   name: 'UserListNew',
   data () {
      return {
         queryData: {
            unionId: '',
            mobile: '',
            shareMobile: ''
         },
         locale: {
            emptyText: () => {
               let style = {
                  fontSize: '15px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
               }
               return (
                  <div style={style}>
                     <a-icon type="search" />&nbsp;
                     <span>请输入搜索条件查询</span>
                  </div>
               )
            }
         },
         pageNum: 1,
         pageSize: 10,
         total: 0,
         loading: false,
         columns: [
            {
               title: '序号',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  return index + 1
               }
            },
            {
               title: '用户union_id',
               dataIndex: 'unionId',
               align: 'center',
               ellipsis:true
            },
            {
               title: '用户手机号',
               dataIndex: 'mobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '会员等级',
               dataIndex: 'userLevelStr',
               align: 'center',
               ellipsis:true
            },
            {
               title: '晋级时间',
               dataIndex: 'pushTimeStr',
               align: 'center',
               ellipsis: true
            },
            {
               title: '权益积分合计',
               dataIndex: 'reward', 
               align: 'center',
               ellipsis:true,
               customRender: (_, { points = 0, pointsEd = 0, pointsIng = 0 }) => {
                  return (
                     <div>
                        <div>总积分：{points}</div>
                        <div>已到账：{pointsEd}</div>
                        <div>待到账：{pointsIng}</div>
                     </div>
                  )
               }
            },
            {
               title: '销售奖励合计',
               dataIndex: 'reward', 
               align: 'center',
               ellipsis:true,
               customRender: (_, { reward = 0, rewardEd = 0, rewardIng = 0 }) => {
                  return (
                     <div>
                        <div>总积分：{reward}</div>
                        <div>已到账：{rewardEd}</div>
                        <div>待到账：{rewardIng}</div>
                     </div>
                  )
               }
            },
            {
               title: '推荐人',
               dataIndex: 'shareMobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '推荐时间',
               dataIndex: 'shareTimeStr',
               align: 'center',
               ellipsis: true,
            }
         ],
         tableData: []
      }
   },
   computed: {
      handleParams () {
         let p = {
            ...this.queryData,
            // pageNum: this.pageNum,
            // pageSize: this.pageSize
         }
         for (const key in p) {
            if (p[key] == '' || p[key] == undefined) {
               delete p[key]
            }
         }
         return p
      }
   },
   created () {
      // this.loadTableData()
   },
   methods: {
      loadTableData () {
         if (Object.values(this.handleParams).length === 0) {
            this.$message.info('请输入搜索条件查询')
            return
         }
         this.loading = true
         api.shopkeeperManage.userListNew(this.handleParams).then(res => {
            if (res.code === '00000') {
               this.locale = {}
               this.total = res.data.total
               this.tableData = res.data.dataList
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => {
            this.loading = false
         })
      },
   }
}
</script>

<style>

</style>